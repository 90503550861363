<template>
  <div class="panel panel-default">
    <div class="panel-heading">土种管理 > 新增土种数据</div>
    <div class="panel-body">
      <div class="table-responsive input-form">
        <form action="" @submit.prevent="handleSubmit">
          <ul>
            <li>
              <label for="">土类:</label>
              <!-- <input type="text" v-model="group_id" /> -->
              <select v-model="group_id">
                <option
                  v-for="item in classArr"
                  :key="item.group_id"
                  :value="item.group_id"
                >
                  {{ item.group_name }}
                </option>
              </select>
            </li>
            <li>
              <label for="">亚类:</label>
              <!-- <input type="text" v-model="subgroup_id" /> -->
              <select v-model="subgroup_id">
                <option
                  v-for="item in subClassList"
                  :key="item.subgroup_id"
                  :value="item.subgroup_id"
                >
                  {{ item.subgroup_gb_name }}
                </option>
              </select>
            </li>
            <li>
              <label for="">土种ID:</label
              ><input type="text" v-model="soil_id" />
            </li>
            <li>
              <label for="">土种名称:</label
              ><input type="text" v-model="soil_type_name" />
            </li>
            <li>
              <label for="">土种描述:</label>
              <textarea v-model="description" cols="60" rows="6"></textarea>
            </li>
            <li>
              <label for="">分布和地形地貌:</label>
              <textarea v-model="landform" cols="60" rows="2"></textarea>
            </li>
            <li>
              <label for="">面积（公顷）:</label
              ><input type="text" v-model="area_hm" />
            </li>
            <li>
              <label for="">面积（万亩）:</label
              ><input type="text" v-model="area_wanmu" />
            </li>
            <li>
              <label for="">母质:</label><input type="text" v-model="parent" />
            </li>
            <li>
              <label for="">剖面构型:</label
              ><input type="text" v-model="profile_pattern" />
            </li>
            <li>
              <label for="">有效土体深度:</label
              ><input type="text" v-model="effective_depth" />
            </li>
            <li>
              <label for="">主要性状:</label>
              <textarea v-model="main_characters" cols="60" rows="4"></textarea>
            </li>
            <li>
              <label for="">土壤障碍因子:</label
              ><input type="text" v-model="soil_constraint_factor" />
            </li>
            <li>
              <label for="">生产性能:</label>
              <textarea
                v-model="production_performance"
                cols="60"
                rows="6"
              ></textarea>
            </li>
            <li>
              <label for="">土地利用:</label
              ><input type="text" v-model="landuse" />
            </li>

            <li>
              <input type="submit" class="btn btn-default" value="提交" />
              <!-- 表单校验 -->
              <span v-if="errors.length">
                <b> 请检查下列问题: </b
                ><span v-for="error in errors" :key="error">{{ error }} </span>
              </span>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import globalData from "@/global.js";
import axios from "axios";
import qs from "qs";

export default {
  data() {
    return {
      classArr: globalData.classArr,
      subClassList: [],
      selectClass: "",
      errors: [], // 表单校验

      soil_id: "",
      group_id: "",
      subgroup_id: "",
      soil_type_name: "",
      description: "",
      landform: "",
      area_hm: "",
      area_wanmu: "",
      parent: "",
      profile_pattern: "",
      effective_depth: "",
      main_characters: "",
      soil_constraint_factor: "",
      production_performance: "",
      landuse: "",
    };
  },
  updated() {
    if (this.group_id != this.selectClass) {
      this.selectClass = this.group_id;
      this.subgroup_id = "";
      // 亚类
      axios
        .get(
          `./data_cultivate/getSubGroupBaseMessageByGroup_id?group_id=${this.selectClass}`
        )
        .then((res) => {
          // console.log(res.data);
          this.subClassList = res.data.data.map((element) => {
            return {
              subgroup_gb_name: element.subgroup_gb_name,
              subgroup_id: element.subgroup_id,
            };
          });
        });
    }
  },
  methods: {
    handleSubmit() {
      const {
        soil_id,
        group_id,
        subgroup_id,
        soil_type_name,
        description,
        landform,
        area_hm,
        area_wanmu,
        parent,
        profile_pattern,
        effective_depth,
        main_characters,
        soil_constraint_factor,
        production_performance,
        landuse,
      } = this;
      let data = {
        soil_id,
        group_id,
        subgroup_id,
        soil_type_name,
        description,
        landform,
        area_hm,
        area_wanmu,
        parent,
        profile_pattern,
        effective_depth,
        main_characters,
        soil_constraint_factor,
        production_performance,
        landuse,
      };
      // console.log(data);

      // 表单校验
      this.errors = [];

      if (!this.soil_id) {
        this.errors.push("必填土种ID;");
      }
      if (!this.soil_type_name) {
        this.errors.push("必填土种名称;");
      }
      if (!this.group_id) {
        this.errors.push("必选土类;");
      }
      if (!this.subgroup_id) {
        this.errors.push("必选亚类;");
      }

      if (
        this.soil_id &&
        this.soil_type_name &&
        this.group_id &&
        this.subgroup_id
      ) {
        //   console.log(data)
        // 提交表单
        axios
          .post(`./data_cultivate/addCultivateMessage`, qs.stringify(data))
          .then((res) => {
            console.log(res.data);
            // 更新数据, 页面跳转
            if (
              this.$store.state.class == group_id &&
              this.$store.state.subClass == subgroup_id
            )
              this.$store.dispatch("getSpeciesListAction");
            this.$message({
              message: "新增土种成功",
              type: "success",
            });
            this.$router.push(`/soilspecieslist`);
          });
      }
    },
  },
};
</script>
